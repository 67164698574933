import React, { useState } from "react";

export const myContext = React.createContext();

const Provider = (props) => {
  const [showBox, setShowBox] = useState("init");
  const [homeImageLoaded, setHomeImageLoaded] = useState(false);
  const [pageColor, setPageColor] = useState("light");
  const [homeImage, setHomeImage] = useState("");

  return (
    <myContext.Provider
      value={{
        homeImage,
        setHomeImage,
        homeImageLoaded,
        setHomeImageLoaded,
        pageColor,
        setPageColor,
        showBox,
        setShowBox,
      }}
    >
      {props.children}
    </myContext.Provider>
  );
};

const wrapProvider = ({ element }) => <Provider>{element}</Provider>;

export default wrapProvider;
