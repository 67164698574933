import React from "react";
import Provider from "./src/provider";
import "./src/styles/normalize.css";
import "./src/styles/global.css";
// import Div100vh from "react-div-100vh";

export const wrapRootElement = Provider;

// export const wrapPageElement = ({ element }) => {
//   return <Div100vh style={{ minHeight: "100rvh" }}>{element}</Div100vh>;
// };
